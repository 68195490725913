import $ from 'jquery'
import 'select2'
import 'select2/dist/css/select2.css'

$(function(){
  if($('#advanced-search').length > 0){
    $('.select').select2({
      width: '100%',
      dropdownParent: $('#advanced-search'),
    });
  }else if($('#topic_product_id_arr').length > 0){
    $('.select#topic_product_id_arr').select2({
      width: '100%',
      maximumSelectionLength: 2
    });
  }else{
    $('.select').select2({
      width: '100%',
      // select 移除搜尋功能
      minimumResultsForSearch: -1,
    });
    $('select#work_company_ids , select#work_partner_ids').select2({
      width: '100%',
      maximumSelectionLength: 1
    });
  }

  $('.link-select').on("select2:select", function(e) {
    self.currentDepartmentId = e.params.data.id
    let link = $(this).data('link');

    if(e.params.data.id == 0){ 
         window.location = link;
    }else{
       window.location = link + "/" + self.currentDepartmentId;
    }
  });
})


