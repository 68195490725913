$(function(){
    let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    svgimg();
    // $('.carousel').carousel({
    //     interval: 5000
    // });

    $("body").on("click", "#menuBtn", function(e){
        var target = $(this).data("target");

        $(target).toggleClass("show");
        $(this).toggleClass("open");
        $("body").toggleClass("hidebar");

        $("#header").toggleClass("open");
        $("#mainmenu").toggleClass("show");
        e.preventDefault();
    })

    $("body").on("click", ".gotop", function(e){
        if(isSafari){
            $("body, html").animate({
                scrollTop: 0
            }, 600);
        }else{
            window.scroll({
                top: 0,
                behavior: 'smooth'
            });
        }

        e.preventDefault();
    })

    $(".disable").on("click", function(e){
        e.preventDefault();
    });

    if ($('.slide-up-list').length > 0) {
        $(".slide-up-item").each(function(index){
            var delayTime = index * 0.2;
            $(this).css({
                "transition-delay": delayTime + "s",
                "-webkit-transition-delay": delayTime + "s",
            });
            $(this).addClass("in");
        })
    }
    $('#menuBtn').on('click',function () {
        if(!$('#mainmenu').hasClass('show')){
            $('.mainmenu-link').each(function (index) {
                let menuDelayTime = index * 0.05;
                $(this).css({
                    "transition-delay": menuDelayTime + "s",
                    "-webkit-transition-delay": menuDelayTime + "s",
                });
                $(this).addClass("show");

                if($(this).next(".submenu").length > 0){
                    $(this).next(".submenu").css({
                        "transition-delay": menuDelayTime + "s",
                        "-webkit-transition-delay": menuDelayTime + "s",
                    });
                    $(this).next(".submenu").addClass("show");
                }
            })
        }else{
            $('.mainmenu-link').removeClass("show");
            $('.mainmenu-link').css({
                "transition-delay": '0s',
                "-webkit-transition-delay": '0s',
            });

            $(".submenu").css({
                "transition-delay": " 0s",
                "-webkit-transition-delay": "0s",
            });
            $(".submenu").removeClass("show");
        }
    })   
    
    $(".hassub").on('click',function (e) {
        if($(window).width() < 1440){
            if($(this).next(".submenu").length > 0){
                $(this).next(".submenu").toggle();
                $(this).parents(".mainmenu-item").toggleClass("active");
                e.preventDefault();
            }
        }
    })
})

function svgimg(){
    $('img.svgimg').each(function(){
        var $img = $(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');
    
        jQuery.get(imgURL, function(data) {
            // Get the SVG tag, ignore the rest
            var $svg = jQuery(data).find('svg');
    
            // Add replaced image's ID to the new SVG
            if(typeof imgID !== 'undefined') {
                $svg = $svg.attr('id', imgID);
            }
            // Add replaced image's classes to the new SVG
            if(typeof imgClass !== 'undefined') {
                $svg = $svg.attr('class', imgClass+' replaced-svg');
            }
    
            // Remove any invalid XML tags as per http://validator.w3.org
            $svg = $svg.removeAttr('xmlns:a');
            
            // Check if the viewport is set, else we gonna set it if we can.
            if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
            }
    
            // Replace image with new SVG
            $img.replaceWith($svg);
    
        }, 'xml');
    });	
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}
//節流函式
function throttle(fun, delay) {
    let last, deferTimer
    return function (args) {
      let that = this
      let _args = args
      let now = +new Date()
      // 1. 如果last不存在代表第一次執行函數，所以可以直接執行函數
      // 2. 如果now小於 last + delay表示上一次執行函數的時間已經超過delay的時間
      //    所以可以直接執行函數(else代碼塊)
      if (last && now < last + delay) {
        // 底下確保不會發生
        // 最後執行代碼的時候，因為刷新last，造成不會執行最後的結果
        clearTimeout(deferTimer)
        deferTimer = setTimeout(function () {
          last = now
          fun.call(that, _args)
        }, delay)
      
      } else {
        last = now
        fun.call(that, _args)
      }
    }
}
// $(window).on("scroll", function(){
// 	let scrolltop = $(window).scrollTop();
//     if($('#header').length > 0  ){
//         throttle(headerZoom(scrolltop),1000)
//     }
// })
// /**
//  * header 縮放用方法
//  * @param {number} scrolltop 
//  */
// function headerZoom (scrolltop){
//  if(scrolltop > 0){
//     $('#header').addClass('shrink')
//  }else{
//     $('#header').removeClass('shrink')
//  }
// }