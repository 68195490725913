// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
window.bootstrap = require('bootstrap');

import '@master/css';
import "./bootstrap_custom.js";
import "./common/notice/notice"
import "./common/sweetalert/sweetalert"
import "@fortawesome/fontawesome-free/css/all"
import "./common/slickjs/slickjs"
import "./common/select2/select2"
import "./common/jquery.waypoints.min.js"
import "./common/validation/validation"
import "./custom.js";
import "./common/cookie/cookieconsent.min.js";
import "./common/fullcalendar.js";

import("../styles/style.scss");



// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
