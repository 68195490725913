import { Calendar } from '@fullcalendar/core';
import { Tooltip } from 'bootstrap';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';

document.addEventListener('DOMContentLoaded', function () {
	if ($("#calendar").length > 0) {
		let calendarEvent = $('#calendar').data('calendar-event')
		let calendarFormat = []
		for (let index = 0; index < calendarEvent.length; index++) {
			calendarFormat.push({
				title: calendarEvent[index].title,
				start: calendarEvent[index].start,
				end: calendarEvent[index].end,
				color: calendarEvent[index].color,
				url: calendarEvent[index].url ? calendarEvent[index].url : '#',
			})

		}
		var calendarEl = document.getElementById('calendar');

		var fullcalendar = new Calendar(calendarEl, {
			plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
			headerToolbar: {
				left: 'prev,next today',
				center: 'title',
				right: 'dayGridMonth,timeGridWeek'
			},
			navLinks: true, // can click day/week names to navigate views
			editable: true,
			events: calendarFormat,
			eventDidMount: function (info) {
				const tooltip = new Tooltip(info.el, {
				  title: info.event.title,
				  placement: 'top',
				  trigger: 'hover',
				  container: 'body',
				});
			  },
			eventClick: function (info) {
				info.jsEvent.preventDefault(); // don't let the browser navigate
				if (info.event.url) {
					window.open(info.event.url);
				}
			}
		});

		fullcalendar.render();
	} // end if
});
