import toastr from 'toastr'

//https://codeseven.github.io/toastr/demo.html
window.notice = function(type, content, title) {
  toastr.options = {
    closeButton: true,
    progressBar: true,
    showMethod: 'slideDown', // fadein、slideDown
    positionClass: "toast-top-center",
    timeOut: 4000
  };
  toastr[type](content, title); //type:info,error,success,warning
}
