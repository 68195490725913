import $ from 'jquery';
window.$ = window.jQuery = $;
import 'slick-carousel';

document.addEventListener('DOMContentLoaded', function () {
  if ($(".slick").length > 0) {
    $(".slick").slick({
      arrows: false,
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 3,
      slidesToScroll: 3,
      autoplay: true,
      adaptiveHeight: true,
      dotsClass: 'slick-dots webTextEnd',
      responsive: [
        {
          breakpoint: 1440,
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    })
  }

  if ($(".big-slick").length > 0) {
    var bigSlick = $(".big-slick").slick({
      arrows: false,
      dots: true,
      infinite: true,
      speed: 300,
      autoplay: true,
      dotsClass: 'slick-dots webTextEnd',
      slidesToShow: 1,
    })
  }

  if ($(".article-slick").length > 0) {
    $(".article-slick").slick({
      arrows: false,
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      adaptiveHeight: true,
      dotsClass: 'slick-dots webTextEnd'
    })
  }

  if ($(".index-company-list").length > 0) {
    indexCompany();

    $(window).on("resize", function () {
      indexCompany();
    }) // end resize
  }

  if ($(".square-list").length > 0) {
    $(".square-list").slick({
      arrows: true,
      dots: false,
      infinite: true,
      speed: 300,
      slidesToShow: 7,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 5
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            dots: true,
            slidesToShow: 2,
          }
        }
      ]
    })
  }

  if ($(".image-slick").length > 0) {
    $(".image-slick").slick({
      arrows: false,
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      adaptiveHeight: true,
      dotsClass: 'slick-dots webTextEnd'
    })
  }

  if ($(".product-banners").length > 0) {
    $(".product-banners").slick({
      slidesToShow: 2,
      arrows: true,
      dots: false,
      infinite: true,
      speed: 300,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            dots: false,
            slidesToShow: 1
          }
        }
      ]
    })
  }


  if ($(".index-project").length > 0) {
    indexProject();

    $(window).on("resize", function () {
      indexProject();
    }) // end resize
  } // end .index-project

  if ($(".page-slick").length > 0) {
    const hideArrow = !$(".page-slick").data('noshowdot')
    const setting = {
      arrows: hideArrow,
      dots: false,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      // adaptiveHeight: true,
      dotsClass: 'slick-dots webTextEnd'
    }
    if ($('#lightbox').length > 0) {
      const slickImgLength = $('.ql-editor img').length - 1
      $('.ql-editor img').each(function (index) {
        const imageSource = $(this).attr('src');
        $('.page-slick').append(' <img src="' + imageSource + '" class="mx-auto  ">')

      });
      const images = $('.page-slick img'); 
      let loadedCount = 0;  

      images.on('load', function () {
        loadedCount++;
        if (loadedCount === slickImgLength) {
          $(".page-slick").slick(setting)
        }
      });
    }
    else {
      $(".page-slick").slick(setting)
    }




  }// end .page-slick
});


function indexProject() {
  var projectListSetting = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 9999,
        settings: "unslick"
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1
        }
      }
    ]
  }

  var projectSlide = $(".index-project").find(".project-list");

  if (!projectSlide.hasClass('slick-initialized')) {
    projectSlide.slick(projectListSetting);
  }
}

function indexCompany() {
  var companyListSetting = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 9999,
        settings: "unslick"
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          arrows: false,
        }
      }
    ]
  }

  if (!$(".index-company-list").hasClass('slick-initialized')) {
    $(".index-company-list").slick(companyListSetting)
  }
}
